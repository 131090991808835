var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-bottom":"10px","height":"200px !important"}},[_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.processingData,"variant":'light',"opacity":0.85,"blur":'2px',"rounded":"sm"}},[_c('div',[(_vm.step === 1)?_c('div',{staticClass:"m-auto block",staticStyle:{"max-width":"439px"}},[(_vm.counterSites === 1)?_c('div',[_c('h2',{staticClass:"color-text-secondary font-weight-bolder",staticStyle:{"font-weight":"700","font-size":"32px"}},[_vm._v(" Let´s get started"),_c('span',{staticClass:"text-primary"},[_vm._v(".")])]),_c('span',{},[_vm._v("Hooray! We´re glad to welcome you "),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.userData.email))]),_vm._v(" in Seocloud. Add your first site")])]):_c('div',[_c('h2',{staticClass:"color-text-secondary font-weight-bolder",staticStyle:{"font-weight":"700","font-size":"32px"}},[_vm._v(" Add a site"),_c('span',{staticClass:"text-primary"},[_vm._v(".")])])])]):_vm._e(),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[(_vm.step === 1)?_c('b-row',{staticClass:"m-auto block",staticStyle:{"max-width":"439px"}},[_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('b-form-group',{staticStyle:{"font-weight":"700"},attrs:{"label-for":"siteName"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"font-weight":"700","font-size":"15px","color":"#656B85"}},[_vm._v("Site name")])]},proxy:true}],null,false,1582225141)},[_c('validation-provider',{attrs:{"name":'Site name',"rules":"required|max: 72"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mt-1",attrs:{"placeholder":"Write you site name","size":"lg","state":errors.length > 0 ? false:null},model:{value:(_vm.siteName),callback:function ($$v) {_vm.siteName=$$v},expression:"siteName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2056801028)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('b-form-group',{staticStyle:{"font-weight":"700"},attrs:{"label-for":"siteURL"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"font-weight":"700","font-size":"15px","color":"#656B85"}},[_vm._v("Homepage URL")])]},proxy:true}],null,false,2514446830)},[_c('validation-provider',{attrs:{"name":"URL","rules":"required|url|max: 72"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mt-1 ",attrs:{"placeholder":"Add your URL","size":"lg","state":errors.length > 0 ? false:null},model:{value:(_vm.siteURL),callback:function ($$v) {_vm.siteURL=$$v},expression:"siteURL"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1213043969)})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errorExistSite))])],1),(_vm.originAddButton !== 'my-sites')?_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('div',{class:!_vm.siteProject ? '' : _vm.siteProject && _vm.$route.params.idPro === _vm.siteProject.uid ? 'p-1' : '',style:(!_vm.siteProject ? '' : _vm.siteProject && _vm.$route.params.idPro === _vm.siteProject.uid ? 'background: #CA34FF1A; border: 1px solid #7838ff; border-radius: 10px;' : '')},[_c('validation-provider',{attrs:{"name":"Project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"siteProject","state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"label",fn:function(){return [(!_vm.siteProject ? '' : _vm.siteProject && _vm.$route.params.idPro ? '' : '')?_c('feather-icon',{staticClass:"mr-50",staticStyle:{"color":"#7838FF"},attrs:{"icon":"AlertCircleIcon"}}):_vm._e(),_c('span',{staticStyle:{"font-weight":"700","font-size":"15px","color":"#656B85"}},[_vm._v("Add your site to a Project")]),_c('span',{staticClass:"ml-50",staticStyle:{"color":"#c1c1c1"}},[_vm._v("(optional)")])]},proxy:true}],null,true)},[_c('v-select',{staticClass:"mt-50 select-project-add-site",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.arrayProjects,"clearable":false,"label":"title"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var desc = ref.desc;
var icon = ref.icon;
var shared = ref.shared;
return [_c('span',{style:("display: flex;")},[(icon.includes('#'))?_c('div',{staticClass:"mr-50",style:(("width: 12px; height: 12px; border-radius: 4px; background: " + icon + "; margin-top: 4px;"))}):_c('feather-icon',{staticClass:"align-middle mr-50",attrs:{"icon":icon,"size":"16"}}),_c('span',[_vm._v(" "+_vm._s(desc)),_c('span',{staticStyle:{"color":"#CA34FF !important"}},[_vm._v(_vm._s(shared ? ' (Shared access)' : ''))])])],1)]}}],null,true),model:{value:(_vm.siteProject),callback:function ($$v) {_vm.siteProject=$$v},expression:"siteProject"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,2294010364)})],1)]):_vm._e(),_c('div',{staticClass:"w-100 mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-continue-addsite float-right",attrs:{"variant":"primary","disabled":_vm.processingData ? true : false},on:{"click":function($event){$event.preventDefault();return _vm.validationStepOne.apply(null, arguments)}}},[_vm._v(" Add site ")])],1)],1):_vm._e()],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }