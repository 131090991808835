<template>
  <div>
    <span
      class="float-left pt-1"
      style="font-weight: 700;"
    >SEOcloud copilot Pages</span>
    <div class="custom-search d-flex justify-content-end mb-1">

      <b-input-group
        class="input-group-merge ml-1 "
        style="width: 213px; height: 44px; border: 1px solid #A7AAB9; border-radius: 22px;"
      >
        <b-input-group-prepend style="border: transparent; border-radius: 20px;">
          <div
            class="input-group-text"
            style="border: transparent; border-radius: 20px;"
          >
            <feather-icon icon="SearchIcon" />
          </div>
        </b-input-group-prepend>
        <b-form-input
          v-model="searchTerm"
          placeholder="Search"
          style="border: transparent; background: none;"
          type="text"
        />
      </b-input-group>
    </div>

    <b-overlay
      :show="loaderPages"
      rounded="xl"
      no-fade
      class="tabs-propages"
    >
      <div class="top-scroll-horizontal-propages" style="overflow-x: scroll;"><div class="div1" id="tab-pro-sc2" style="height: 20px;"></div></div>
      <b-table
        :items="dataPropages ? dataPropages.filter(ele => !ele.cUrl.includes('.xml')) : dataPropages2.filter(ele => !ele.cUrl.includes('.xml'))"
        :fields="fieldsPagesOverview"
        :select-mode="selectMode"
        :filter="searchTerm"
        :filter-ignored-fields="['inlinks','inlinksList']"
        foot-clone
        responsive
        ref="selectableTable"
        :selectable="true"
        sticky-header="350px"
        style-class="vgt-table-pro"
        style="max-height: calc(-345px + 100vh); overflow-y: auto;"
        class="vgt-table-pro"
        id="tab-pro-sc1"
        show-empty
        @filtered="onFiltered"
        @row-selected="onRowSelected"
        @row-clicked="rowClicked"
      >
        <template #empty="">
          <span>Page not found</span>
        </template>
        <template #emptyfiltered="">
          <span>Page not found</span>
        </template>
        <template #cell(selected)="data">
          <div class="d-flex">
            <b-button
              style="background: transparent !important; border: 0px; display: inline !important; margin-right: 12px !important;"
              target="_blank"
              :href="data.item.cUrl"
              class="p-0 ml-0 eyeBtns linkURL"
            >
              <feather-icon
                icon="ExternalLinkIcon"
                class="cursor-pointer eyeBtns"
                style="color: #e3e3e3 !important;"
                size="18"
              />
            </b-button>
            <b-form-checkbox
              v-model="valueHome"
              class="custom-control-primary"
              :style="''"
              :disabled="data.item.url === 'Homepage' || data.item.url === 'Home' ? true : false"
              :value="true"
              @change="observerCheckbox(data)"
              v-if="data.item.url === 'Homepage'"
            >
            </b-form-checkbox>
            <b-form-checkbox
              v-model="data.rowSelected"
              class="custom-control-primary"
              :style="''"
              :disabled="data.item.url === 'Homepage' ? true : false"
              :value="true"
              @change="observerCheckbox(data)"
              v-else
            >
            </b-form-checkbox>
          </div>
        </template>
        <template
          #cell(page)="data"
        >
          {{ data.item.title ? data.item.title.substring(0, 60) + (data.item.title.length > 60 ? '...' : '') : data.item.page.substring(0, 60) + (data.item.page.length > 60 ? '...' : '') }}
          <br>
          <span style="font-size: 12px; color: #b9b9b9;">{{ data.item.url.substring(0, 75) + (data.item.url.length > 75 ? '...' : '') }}</span>
        </template>
        <template
          #cell(pro)="data"
        >
          {{ functionPro(data) }}
          <b-button
            style="background: transparent !important; border: 0px; display: inline !important;"
            class="p-0 ml-0"
          >
            <b-img-lazy
              v-if="data.rowSelected || data.item.url === 'Homepage'"
              fluid
              :src="proIcon"
              alt="pro page img"
              width="17"
              height="16"
              class="mr-50"
              loading="lazy"
            />
            <b-img-lazy
              v-else
              fluid
              :src="proIconNone"
              alt="pro page img"
              width="17"
              height="16"
              class="mr-50"
              loading="lazy"
            />
          </b-button>
        </template>
        <template
          #cell(inlinks)="data"
        >
          {{ data.item.inlinks }}
        </template>
        <template
          #cell(errors)="data"
        >
          {{ data.item.errors }}
        </template>

        <template #foot(page)="">
          <div style="text-align: right !important; margin: auto; width: 100%;" v-if="(crawls && crawls.estatus === 'running') || crawlRunning ? true : false">
            <b-spinner
              class=""
              style="margin-right: 110px; color: #bbb !important;"
            />
          </div>
          <span v-else></span>
        </template>

        <template #foot(pro)="">
          <span></span>
        </template>

        <template #foot(inlinks)="">
          <span></span>
        </template>

        <template #foot(errors)="">
          <span></span>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BImgLazy,
  BButton, BFormCheckbox, BTable, BTableSimple, BThead, BTbody, BTr, BTd, BLink, BOverlay, BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BImgLazy,
    BButton,
    BFormCheckbox,
    BTable,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BLink,
    BOverlay,
    BSpinner,
  },
  data() {
    return {
    //   pageLength: 3,
      proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
      proIconNone: require('@/assets/images/icons/i-pro-badge-none.svg'),
      dir: false,
      valueHome: true,
      columns: [
        {
          label: 'Page',
          field: 'page',
          sortable: false,
          thClass: 'thTableSitesPage',
          tdClass: 'tdTableSitesPage',
        },
        {
          label: 'URL',
          field: 'url',
          sortable: false,
          thClass: 'thTableSitesURLProPages',
          tdClass: 'tdTableSitesURLProPages',
        },
        {
          label: 'Inlinks',
          field: 'inlinks',
          sortable: false,
          thClass: 'thTableSitesShortInlinks',
          tdClass: 'tdTableSitesShortInlinks',
        },
        {
          label: 'Errors',
          field: 'errors',
          sortable: false,
          thClass: 'thTableSitesShortErrors',
          tdClass: 'tdTableSitesShortErrors',
        },
        {
          label: 'Exist',
          field: 'exist',
          sortable: false,
          thClass: 'thTableSitesShortExist',
          tdClass: 'tdTableSitesShortExist',
        },
      ],
      rows: [
        {
          page: 'Home',
          pro: true,
          url: 'Homepage',
          inlinks: '67',
          errors: '324',
          exist: true,
          id: '805149744847148806725',
        },
        {
          page: 'Shop',
          pro: true,
          url: '/shop',
          inlinks: '26',
          errors: '321',
          exist: true,
          id: '805149744847148806725',
        },
        {
          page: 'Blog',
          pro: true,
          url: '/blog',
          inlinks: '24',
          errors: '178',
          exist: true,
          id: '805149744847148806725',
        },
        {
          page: 'About',
          pro: false,
          url: '/about',
          inlinks: '12',
          errors: '32',
          exist: false,
          id: '805149744847148806725',
        },
        {
          page: 'Contact',
          pro: false,
          url: '/contact',
          inlinks: '11',
          errors: '324',
          exist: false,
          id: '805149744847148806725',
        },
        {
          page: 'Test',
          pro: false,
          url: '/test',
          inlinks: '11',
          errors: '324',
          exist: false,
          id: '805149744847148806725',
        },
        {
          page: 'Store',
          pro: false,
          url: '/store',
          inlinks: '11',
          errors: '324',
          exist: false,
          id: '805149744847148806725',
        },
        {
          page: 'Clients',
          pro: false,
          url: '/clients',
          inlinks: '11',
          errors: '324',
          exist: false,
          id: '805149744847148806725',
        },
        {
          page: 'Overview',
          pro: false,
          url: '/overview',
          inlinks: '11',
          errors: '324',
          exist: false,
          id: '805149744847148806725',
        },
        {
          page: 'FAQ',
          pro: false,
          url: '/faq',
          inlinks: '11',
          errors: '324',
          exist: false,
          id: '805149744847148806725',
        },
        {
          page: 'Help',
          pro: false,
          url: '/help',
          inlinks: '11',
          errors: '324',
          exist: false,
          id: '805149744847148806725',
        },
      ],
      searchTerm: '',
      rowSelected: '',
      counterRowsSelected: 0,
      fieldsPagesOverview: [
        {
          key: 'selected',
          label: '',
          thClass: 'thTableSitesSelectShortOverviewErrorsPro',
          tdClass: 'tdTableSitesSelectShortOverviewErrorsPro',
        },
        {
          key: 'page',
          label: 'Page',
          thClass: 'thTableSitesPageShortOverviewErrors',
          tdClass: 'tdTableSitesPageShortOverviewErrors',
        },
        {
          key: 'pro',
          label: 'SEOcloud copilot',
          thClass: 'thTableSitesShortOverviewErrorsPro',
          tdClass: 'tdTableSitesShortOverviewErrorsPro'
        },
        {
          key: 'inlinks',
          label: 'Inlinks',
          thClass: 'thTableSitesShortOverviewErrors',
          tdClass: 'tdTableSitesShortOverviewErrors',
        },
        {
          key: 'errors',
          label: 'Errors',
          thClass: 'thTableSitesShortOverviewErrors',
          tdClass: 'tdTableSitesShortOverviewErrors',
        }
      ],
      dataPropages2: [],
      selectMode: 'multi',
      selected: [],
      sortByOverview: 'pro',
      sortDescOverview: true,
      loaderPages: true,
      selectedPro: [],
      dataSearch: [],
      selectedProUrl: [],
      originalPropages: [],
      validationSearchTerm: '',
      filteredItems: [],
      clickEvent: false,
      rowClickedData: '',
      resetSearch: false,
      timerInfiniteErrors: null,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    searchTerm(val) {
      setTimeout(() => {
        const collection = document.getElementsByClassName('vgt-text-disabled')
        if(collection[0] && val) {
          collection[0].innerHTML = 'Page not found'
        }  
      }, 1000)

      if(!val) {
        setTimeout(() => {
          this.resetSearch = false
          this.selectDataPropagesSearch()
        }, 800)
      } else {
        this.resetSearch = true
        this.dataPropages.forEach(ele => {
          // console.log(ele.pro)
          if(this.selectedProUrl.filter(item => item === ele.cUrl).length >= 1) {
            // console.log(ele,ele.cUrl,ele.id)
            // ele.selected = true
            ele.selectedInPro = true
            ele.rowSelected = true
            // this.$refs.selectableTable.selectRow(ele.id)
            // console.log(ele.id)
            // if(this.originalPropages.filter(el => el === ele.id).length === 0) {
            //   this.originalPropages.push(ele.id)
            // }
          }
        })
      }
    },
    openSidePro(val) {
      if(val) {
        this.originalPropages = []
        this.$emit('update:haschanges', false)
        this.resetSearch = false
        this.clickEvent = false
        this.rowClickedData = ''
        this.filteredItems = []
        this.selectedProUrl = []
        this.selectedPro = []
        this.searchTerm = ''
        this.validationSearchTerm = ''
        this.selectDataPropages()

        setTimeout(() => {
          // horizontal scroll
          this.$nextTick(() => {
            $(".top-scroll-horizontal-propages").scroll(() => {
              $(".vgt-table-pro").scrollLeft($(".top-scroll-horizontal-propages").scrollLeft());
            });

            // $(".vgt-table-pro").scroll(() => {
            //   $(".top-scroll-horizontal-propages").scrollLeft($(".vgt-table-pro").scrollLeft());
            // });
          });

          setTimeout(() => {
            const miElemento = document.querySelector('.tabs-propages .vgt-table-pro');
            if(miElemento) {
              miElemento.addEventListener('scroll', () => {
                // console.log(2);
                document.querySelector('.top-scroll-horizontal-propages').scrollLeft = document.querySelector('.tabs-propages .vgt-table-pro').scrollLeft;
              });
            }
          }, 1200)

          const primerElemento = document.getElementById('tab-pro-sc1');
          const segundoElemento = document.getElementById('tab-pro-sc2');

          if(primerElemento) {
            const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
            segundoElemento.style.width = ancho + 'px';
          }
        }, 2000)
      }
    },
    dataPropages() {
      if(this.openSidePro) {
        this.selectDataPropages()
      }
    }
  },
  props: ['dataPropages','openSidePro','listpro','crawls','crawlRunning','prolist','haschanges'],
  created() {
    // this.$http.get('/good-table/basic')
    //   .then(res => { this.rows = res.data })
    this.$nextTick(() => {
      $(".top-scroll-horizontal-propages").scroll(() => {
        $(".vgt-table-pro").scrollLeft($(".top-scroll-horizontal-propages").scrollLeft());
      });

      // $(".vgt-table-pro").scroll(() => {
      //   $(".top-scroll-horizontal-propages").scrollLeft($(".vgt-table-pro").scrollLeft());
      // });
    });

    setTimeout(() => {
      const miElemento = document.querySelector('.tabs-propages .vgt-table-pro');
      if(miElemento) {
        miElemento.addEventListener('scroll', () => {
          // console.log(2);
          document.querySelector('.top-scroll-horizontal-propages').scrollLeft = document.querySelector('.tabs-propages .vgt-table-pro').scrollLeft;
        });
      }
    }, 1200)
  },
  mounted() {
    this.timerInfiniteErrors = setInterval(() => {
      // if((this.crawls && this.crawls.estatus === 'running') || this.crawlRunning) {
        this.constructInfinitePro()
      // }
    }, 1000)
  },
  destroyed() {
    clearInterval(this.timerInfiniteErrors)
  },
  methods: {
    constructInfinitePro() {
      // horizontal scroll
        this.$nextTick(() => {
          $(".top-scroll-horizontal-propages").scroll(() => {
            $(".vgt-table-pro").scrollLeft($(".top-scroll-horizontal-propages").scrollLeft());
          });

          // $(".vgt-table-pro").scroll(() => {
          //   $(".top-scroll-horizontal-propages").scrollLeft($(".vgt-table-pro").scrollLeft());
          // });
        });

        setTimeout(() => {
          const miElemento = document.querySelector('.tabs-propages .vgt-table-pro');
          if(miElemento) {
            miElemento.addEventListener('scroll', () => {
              // console.log(2);
              document.querySelector('.top-scroll-horizontal-propages').scrollLeft = document.querySelector('.tabs-propages .vgt-table-pro').scrollLeft;
            });
          }
        }, 1200)

        const primerElemento = document.getElementById('tab-pro-sc1');
        const segundoElemento = document.getElementById('tab-pro-sc2');

        if(primerElemento) {
          const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
          segundoElemento.style.width = ancho + 'px';
        }
    },
    functionPro(payload) {
      // console.log(payload)
        if(payload.rowSelected || payload.item.url === 'Homepage') {
          // if(this.selectedProUrl.includes(payload.item.cUrl)) {
            payload.item.rowSelected = true
            payload.item.selectedInPro = true
            payload.rowSelected = true
            this.$refs.selectableTable.selectRow(payload.index)
            // console.log(payload.item.cUrl, payload.item.selectedInPro)
          // } else {
          //   payload.item.rowSelected = false
          //   payload.item.selectedInPro = false
          //   payload.rowSelected = false
          //   this.$refs.selectableTable.unselectRow(payload.index)
          //   // console.log(payload.item.cUrl, payload.item.selectedInPro)
          // }
        } else {
          // if(this.searchTerm !== this.validationSearchTerm) {
            this.validationSearchTerm = this.searchTerm
            if(this.selectedProUrl.filter(item => item === payload.item.cUrl).length >= 1) {
              if(payload.item.selectedInPro) {
                payload.item.rowSelected = true
                payload.rowSelected = true
                payload.item.selectedInPro = true
                this.$refs.selectableTable.selectRow(payload.index)
              } else {
                payload.item.rowSelected = false
                payload.rowSelected = false
                payload.item.selectedInPro = false
                this.$refs.selectableTable.unselectRow(payload.index)
              }
            } else {
              payload.item.selectedInPro = false
            }
          // }
        }

        // horizontal scroll
        this.$nextTick(() => {
          $(".top-scroll-horizontal-propages").scroll(() => {
            $(".vgt-table-pro").scrollLeft($(".top-scroll-horizontal-propages").scrollLeft());
          });

          // $(".vgt-table-pro").scroll(() => {
          //   $(".top-scroll-horizontal-propages").scrollLeft($(".vgt-table-pro").scrollLeft());
          // });
        });

        setTimeout(() => {
          const miElemento = document.querySelector('.tabs-propages .vgt-table-pro');
          if(miElemento) {
            miElemento.addEventListener('scroll', () => {
              // console.log(2);
              document.querySelector('.top-scroll-horizontal-propages').scrollLeft = document.querySelector('.tabs-propages .vgt-table-pro').scrollLeft;
            });
          }
        }, 1200)

        const primerElemento = document.getElementById('tab-pro-sc1');
        const segundoElemento = document.getElementById('tab-pro-sc2');

        if(primerElemento) {
          const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
          segundoElemento.style.width = ancho + 'px';
        }
    },
    selectDataPropagesSearch() {
      // console.log(this.selectedPro.length)
      // this.loaderPages = true
      setTimeout(() => {
        // this.loaderPages = false
        this.selectedPro.forEach(ele => {
          if(ele) {
            // !this.searchTerm ? console.log(ele) : ''
            // ele.selected = true
            this.$refs.selectableTable.selectRow(ele)
            // console.log(ele.id)
          }
        })
      }, 500)
    },
    selectDataPropages() {
      // console.log('1')
      this.loaderPages = true
      setTimeout(() => {
        this.loaderPages = false
        this.dataPropages.forEach(ele => {
          // console.log(ele.pro)
          if(ele.pro) {
            // ele.selected = true
            ele.selectedInPro = true
            this.$refs.selectableTable.selectRow(ele.id)
            // console.log(ele.id)
            if(this.originalPropages.filter(el => el === ele.id).length === 0) {
              this.originalPropages.push(ele.id)
            }
          }
        })
      }, 1500)
    },
    observerCheckbox(payload) {
      // console.log('2')
      // console.log(payload)
      // console.log(this.selected.length)
      // if(this.selected.length) {
        // if(this.selected.length === this.dataPropages.length) {
        //   this.$refs.selectableTable.clearSelected()
        //   // setTimeout(() => {
        //   //     this.$refs.selectableTable.selectRow(payload.index)
        //   // }, 100)
        //   // this.$refs.selectableTable.unselectRow(payload.index)
        // } else
        if(this.selected.length === 1) {
          // this.$refs.selectableTable.selectAllRows()
          setTimeout(() => {
              // this.selectedPro.push(payload.index)
              this.$refs.selectableTable.selectRow(payload.index)
              // data.item.pro
              // console.log(payload)
          }, 100)
        } else {
          if(payload.rowSelected) {
            // this.selectedPro.push(payload.index)
            this.$refs.selectableTable.selectRow(payload.index)
            // console.log(payload)
          } else {
            this.$refs.selectableTable.unselectRow(payload.index)
          }
        }
      // }
      
      if(this.searchTerm) {
        this.rowClicked(payload.item)
      } else {
        this.onRowSelected([payload])  
      }
    },
    onFiltered(filteredItems) {
      // console.log(filteredItems.length)
      this.filteredItems = filteredItems
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    rowClicked(item) {
      this.clickEvent = true
      this.rowClickedData = item
      setTimeout(() => {
        this.clickEvent = false
      }, 700)
      // console.log(item)
    },
    onRowSelected(items) {
      // console.log('3')
      if(this.searchTerm) {
        if(this.clickEvent) {
          this.$emit('update:haschanges', true)
          this.filteredItems.forEach(item => {
            if(this.rowClickedData.cUrl === item.cUrl) {
              if(item.selectedInPro === true) {
                // filter page of data table
                var filterProArrayTable = this.dataPropages.filter(ele => ele.cUrl === item.cUrl)
                if(filterProArrayTable.length) {
                  filterProArrayTable.forEach(ele => {
                    ele.rowSelected = true
                    ele.selectedInPro = true
                    item.rowSelected = true
                    item.selectedInPro = true
                  })
                }

                // filter data in arrays setters SEOcloud copilot
                var filterSelectedPro = this.selectedPro.filter(ele => ele === item.id)
                if(filterSelectedPro.length) {
                  this.selectedPro = this.selectedPro.filter(ele => ele !== item.id)
                }
                var filterSelectedProUrl = this.selectedProUrl.filter(ele => ele === item.cUrl)
                if(filterSelectedProUrl.length) {
                  this.selectedProUrl = this.selectedProUrl.filter(ele => ele !== item.cUrl)
                }
              } else {
                // filter page of data table
                var filterProArrayTable = this.dataPropages.filter(ele => ele.cUrl === item.cUrl)
                if(filterProArrayTable.length) {
                  filterProArrayTable.forEach(ele => {
                    ele.rowSelected = false
                    ele.selectedInPro = false
                    item.rowSelected = false
                    item.selectedInPro = false
                  })
                }

                // filter data in arrays setters SEOcloud copilot
                var filterSelectedPro = this.selectedPro.filter(ele => ele === item.id)
                if(filterSelectedPro.length === 0) {
                  this.selectedPro.push(item.id)
                }
                var filterSelectedProUrl = this.selectedProUrl.filter(ele => ele === item.cUrl)
                if(filterSelectedProUrl.length === 0) {
                  this.selectedProUrl.push(item.cUrl)
                }
              }
            }
            // console.log(item.selectedInPro)
          })
          this.$emit('update:listpro', this.selectedProUrl)
          // console.log(this.selectedPro,this.selectedProUrl)
          this.rowClickedData = ''
          this.clickEvent = false
        }
      } else {
        this.$emit('update:haschanges', false)
        items.forEach(item => {
          if(item.id) {
            var filterChangesProPages = this.originalPropages.filter(ele => ele === item.id)
            if(filterChangesProPages.length === 0) {
              this.$emit('update:haschanges', true)
            }
          }

          // console.log(item)
          var filter = this.selectedPro.filter(ele => ele === item.id)
          if(!filter.length) {
            // console.log(this.selectedPro,item.id)
            // if(item.selectedInPro) {
            item.rowSelected = true
            item.selectedInPro = true
            this.selectedPro.push(item.id)
            this.selectedProUrl.push(item.cUrl)
            // }
          }
        })

        if(this.resetSearch) {
          this.dataPropages.forEach(item => {
            if(item.selectedInPro === true) {
              // filter page of data table
              var filterProArrayTable = items.filter(ele => ele.cUrl === item.cUrl)
              if(filterProArrayTable.length === 0) {
                // console.log(item.cUrl)
                // filterProArrayTable.forEach(ele => {
                  item.rowSelected = true
                  item.selectedInPro = true
                // })

                // filter data in arrays setters SEOcloud copilot
                var filterSelectedPro = this.selectedPro.filter(ele => ele === item.id)
                if(filterSelectedPro.length === 0) {
                  this.selectedPro.push(item.id)
                }
                var filterSelectedProUrl = this.selectedProUrl.filter(ele => ele === item.cUrl)
                if(filterSelectedProUrl.length === 0) {
                  this.selectedProUrl.push(item.cUrl)
                }
              }
            }
          })
        } else {
          this.dataPropages.forEach(item => {
            if(item.selectedInPro === true) {
              // filter page of data table
              var filterProArrayTable = items.filter(ele => ele.cUrl === item.cUrl)
              if(filterProArrayTable.length === 0) {
                // console.log(item.cUrl)
                // filterProArrayTable.forEach(ele => {
                  item.rowSelected = false
                  item.selectedInPro = false
                // })

                // filter data in arrays setters SEOcloud copilot
                var filterSelectedPro = this.selectedPro.filter(ele => ele === item.id)
                if(filterSelectedPro.length) {
                  this.selectedPro = this.selectedPro.filter(ele => ele !== item.id)
                }
                var filterSelectedProUrl = this.selectedProUrl.filter(ele => ele === item.cUrl)
                if(filterSelectedProUrl.length) {
                  this.selectedProUrl = this.selectedProUrl.filter(ele => ele !== item.cUrl)
                }
              }
            }
          })
        }

        setTimeout(() => {
          var counterPro = 0
          items.forEach(item => {
            if(item.selectedInPro) {
              counterPro += 1
            }
          })

          if(counterPro < this.originalPropages.length) {
            this.$emit('update:haschanges', true)
          }
        }, 700)

        if(!this.searchTerm) {
          // this.selectedPro = []
          // this.selectedProUrl = []
          items.forEach(item => {
            var filter = this.selectedProUrl.filter(ele => ele === item.cUrl)
            if(!filter.length) {
              // item.selectedInPro = true
              // this.selectedPro.push(item.id)
              this.selectedProUrl.push(item.cUrl)
            }
          })
        }
        this.$emit('update:listpro', this.selectedProUrl)
      }
    },
  },
}
</script>

<style>
thead .vgt-checkbox-col input {
  display: none;
}
.thTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
}
.tdTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  /* margin: 20px !important; */
}
.thTableSitesShortInlinks {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  max-width: 68px !important;
  font-weight: 300;
}
.tdTableSitesShortInlinks {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  max-width: 68px !important;
}
.thTableSitesShortErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  max-width: 68px !important;
  font-weight: 300;
}
.tdTableSitesShortErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  max-width: 68px !important;
}
.thTableSitesShortExist {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  max-width: 68px !important;
  font-weight: 300;
}
.tdTableSitesShortExist {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  max-width: 68px !important;
}
.vgt-table-pro.bordered th {
    background: #fff !important;
    border: 1px solid transparent !important;
    margin-top: 20px !important;
    /* font-size: 16px !important; */
    /* color: #181E38 !important; */
}
.thTableSitesShort {
  background: transparent !important;
  /* border: 1px solid transparent !important; */
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 50px !important;
  font-weight: 300;
}
.tdTableSitesShort {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #dcdfe6 !important;
  width: 50px !important;
}
.thTableSitesPage {
  background: transparent !important;
  /* border: 1px solid transparent !important; */
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 490px !important;
  max-width: 490px !important;
  font-weight: 300;
}
.tdTableSitesPage {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #dcdfe6 !important;
  width: 490px !important;
  max-width: 490px !important;
  text-overflow: ellipsis !important;
}
.thTableSitesURLProPages {
  display: none !important;
}
.tdTableSitesURLProPages {
  display: none !important;
}
.thTableSitesSelectShortOverviewErrorsPro {
  background: #fff !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 45px !important;
  max-width: 45px !important;
  font-weight: 300;
  /* text-align: right !important; */
  padding: 0px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
.tdTableSitesSelectShortOverviewErrorsPro {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  /* padding: 0px !important; */
  width: 45px !important;
  max-width: 45px !important;
  padding: 0px !important;
  /* text-align: right; */
  font-size: 16px !important;
}
/* .allSelectedRows1 .tdTableSitesShortInlinks {
  background: #ffffff !important;
}
.allSelectedRows1 .tdTableSitesShortErrors input:checked {
  background: #ffffff !important;
}
.allSelectedRows1 .tdTableSitesShortExist input:checked {
  background: #ffffff !important;
}
.allSelectedRows1 .tdTableSitesShort input:checked {
  background: #ffffff !important;
}
.allSelectedRows1 .tdTableSitesPage input:checked {
  background: #ffffff !important;
}
.allSelectedRows1 .vgt-table-pro .vgt-checkbox-col input:checked {
  background: #ffffff !important;
}
.allSelectedRows2 .tdTableSitesShortInlinks {
  background: #f1f1f5 !important;
}
.allSelectedRows2 .tdTableSitesShortErrors input:checked {
  background: #f1f1f5 !important;
}
.allSelectedRows2 .tdTableSitesShortExist input:checked {
  background: #f1f1f5 !important;
}
.allSelectedRows2 .tdTableSitesShort input:checked {
  background: #f1f1f5 !important;
}
.allSelectedRows2 .tdTableSitesPage input:checked {
  background: #f1f1f5 !important;
}
.allSelectedRows2 .vgt-table-pro .vgt-checkbox-col input:checked {
  background: #f1f1f5 !important;
} */
.eyeBtn {
  color: rgb(198, 198, 198)
}
.eyeBtn:hover {
  color: #A7AAB9 !important;
}
.allSelectedRows1 .vgt-checkbox-col input:checked {
  /* background-color: red !important; */
  accent-color: #8c8c8c !important;
  color: #fff !important;
}
.allSelectedRows2 .vgt-checkbox-col input:checked {
  /* background-color: red !important; */
  accent-color: #be35ff !important;
  color: #fff !important;
}
</style>
